import { Routes } from '@angular/router';
import { HealthyPageComponent } from './components/healthy-page/healthy-page.component';

export const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'healthy',
        component: HealthyPageComponent,
      },
      {
        path: '**',
        loadComponent: () =>
          import('./cms.component').then((mod) => mod.CmsComponent),
      },
    ],
  },
];
