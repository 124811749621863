import {
  CommonModule,
  isPlatformBrowser,
  isPlatformServer,
} from '@angular/common';
import {
  Component,
  Inject,
  Input,
  makeStateKey,
  OnInit,
  Optional,
  ɵPendingTasks as PendingTasks,
  PLATFORM_ID,
  TransferState,
} from '@angular/core';
import { HOST_URL } from '../../../tokens/server.token';
import { StoryblokService } from '../../services/storyblok.service';

const apiResponseKey = makeStateKey<string>('apiResponse');
@Component({
  selector: 'app-healthy-page',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './healthy-page.component.html',
  styleUrl: './healthy-page.component.css',
})
export class HealthyPageComponent implements OnInit {
  revision: string = '';
  cms: boolean = false;
  @Input() heading: string = '';
  loading: boolean = true;

  results: { heroes: { [key: string]: boolean } } = { heroes: {} };
  errors: string[] = [];
  response: string | null = null;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private storyblokService: StoryblokService,
    @Optional() @Inject(HOST_URL) private hostUrl: string,
    @Inject(PendingTasks) private pendingTasks: PendingTasks,
    private transferState: TransferState,
  ) {}

  async ngOnInit() {
    this.revision = this.getBuildInfo();
    if (isPlatformServer(this.platformId)) {
      const taskCleanup = this.pendingTasks.add();
      const apiResponse = await this.fetchDataFromApis();
      this.transferState.set(apiResponseKey, apiResponse);
      this.response = apiResponse;
      this.pendingTasks.remove(taskCleanup);
    } else if (isPlatformBrowser(this.platformId)) {
      this.loading = false;
      this.response = this.transferState.get<string | null>(
        apiResponseKey,
        null,
      );
    }
  }

  async fetchDataFromApis() {
    const promises = [
      fetch(`${this.hostUrl}/api/ahp-project-filters`),
      fetch(`${this.hostUrl}/api/members-all`),
      fetch(`${this.hostUrl}/api/grant-lookup`),
      this.storyblokService.getStory('home', {
        version: 'published',
      }),
    ];

    const results = await Promise.allSettled(promises);
    const jsonObject = results.reduce<{ [key: string]: boolean }>(
      (acc, result, index) => {
        const key = `data${index + 1}`;
        if (result.status === 'fulfilled') {
          acc[key] = true;
        } else {
          acc[key] = false;
        }
        return acc;
      },
      {},
    );
    this.loading = false;
    return JSON.stringify(jsonObject);
  }

  getBuildInfo() {
    return '2024-11';
  }

  async getLDAP() {}
}
