<div class="w-full">
  <div class="max-w-maxw min-w-minw mx-auto responsive-py-md">
    <div class="flex flex-col gap-12 responsive-px">
      <div class="flex flex-col gap-y-6">
        <ng-container> CW App health! </ng-container>
        <ng-container>
          <div class="flex flex-col">
            <div class="">response = [</div>
            <div class="flex flex-col">
              <div>rev: {{ revision }},</div>
              <div>{{ response }},</div>
            </div>
            <div>]</div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
